export interface OrderProduct {
  id: number;
  name: string;
  quantity: number;
  boxQuantity?: number;
  productId: number | null;
  kitId: number | null;
  variationId: number;
  image: string | null;
  exchanged: boolean | undefined;
  receivedQuantity?: number | null;
}

export enum OrderStatus {
  PENDING = 1,
  DECLINED = 10,
  APPROVED = 11,
  APPROVED_LVL2 = 14,
  IN_TRANSIT = 2,
  DELIVERED = 7,
  INBOUNDED = 12,
}

export interface OrderModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  riderId: string;
  orderDate: string;
  deliveryDate: string | null;
  status: OrderStatus;
  products: OrderProduct[];
  number: string;
  hasAccident: boolean;
  note?: string | null;
  inboundDate?: string | null;
  distributionCenter?: string | null;
}
